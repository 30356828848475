// export const { format: formatNumber } = Intl.NumberFormat('en-GB', {
//   notation: 'compact',
//   maximumFractionDigits: 1
// })


// export const { format: formatNumber } = Intl.NumberFormat('en-GB', {
//   notation: 'compact',
//   maximumFractionDigits: 1
// })
import { createApp } from 'vue'
import AdvCpmComponent from '@/components/adv/Cpm.vue'

export const advCpm = {
  
  list: [],
  prepared: [],
  insert_count: 0,
  last_num: 0, 
  last_row: 0, 
  elements: [],   
  cookieNameViewedPackages: 'ac_v_packages',

  viewCpm: async function(id){     
    // useNuxtApp().$api.postAdvViewCpm(id,  this.getSection());    
    useAdvStat().value.push({
      id: id,
      type: 'cpm',
      section: this.getSection(),
      action: 'view',
    });
    // useNuxtApp().$adv.sendStat();
    this.addImpression(id);
    this.addViewedPackage(this.getPackageIdByBannerId(id));
  }, 
  clickCpm: async function(id){     
    // useNuxtApp().$api.postAdvClickCpm(id, this.getSection());       
    useAdvStat().value.push({
      id: id,
      type: 'cpm',
      section: this.getSection(),
      action: 'click',
    })  
    // useNuxtApp().$adv.sendStat();
    useNuxtApp().$ga.clickBanner('cpm');      
  }, 
  addImpression: function(id){
    // console.log('addImpression for' + id);
    if(!this.list.length)
      return false;
    var cpm = this.list.find((item) => {
      if(!item?.banners?.length) return false;
      return item.banners.find((itemBanner) => {
        return itemBanner.id == id;
      });
    });
    if(!cpm)
      return false;
    cpm.sys_impression++;
    // console.log(cpm);
  },
  getPackageIdByBannerId: function(id){
    var packages = this.list;
    for(var itemPackage of packages){
      for(var itemBanner of itemPackage.banners){
        if(itemBanner.id == id)
          return itemPackage.id;
      }
    }
    return null;
  },
  getSection: function(){              
    // var path = router.currentRoute.value.matched[0].path;
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    path = path.split('/');
    var section = path[1] ? path[1] : 'index';
    return section;
  },
  isIgnorePages: function(){   
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    var ignore = [
      'diaries/[\\w-]+/edit/week',
      'grower/[\\w-]+/settings',
      'diaries/edit',
      'brands-manager',
    ];
    ignore = ignore.map((item) => {
      return new RegExp(item);
    });
    for(var item of ignore){
      if(item.test(path))
        return true;
    }
    return false;
  },
  isIgnoreSection: function(){   
    const router = useRouter(); 
    var path = router.currentRoute.value.fullPath;
    const { advList } = useAdvList();
    if(!advList.value?.ignore_sections)
      return false;
    // console.log('advList.value.ignore_sections');
    // console.log(path);
    var ignore_sections = advList.value.ignore_sections;
    for(var ignore_section of ignore_sections){
      if(path.indexOf(ignore_section) >= 0){
        return true;
      }
    }
    return false;
    
  },
  getRandomInt: function(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  isMobile: function(){
    return window.innerWidth <= 768;
  },
  clearCpm: function(){
    // for(var el of this.elements){
    //   el.remove();
    // }        
    this.insert_count = 0;
    this.last_num = 0; 
    this.elements = [];         
  },    
  isReadyCpmApply: function(){
    // if(document.querySelector('.report_boxs') && document.querySelector('.report_box_item'))
      return true
    // return false
  },     
  setLastNum: function(num){
    this.last_num = num;
  },
  setLastRow: function(num){
    this.last_row = num;
  },
  sortByImpressions: function(){
    var section = this.getSection();
    if(section != 'index'){
      // console.log('sortByImpressions before');
      // console.log(this.list);
      // this.list = this.list.sort((a, b) => {
      //   let exists = sorted.indexOf(b.package_id) >= 0;
      //   sorted.push(a.package_id);
      //   return a.sys_impression < b.sys_impression && !exists ? -1 : 1;
      // });
      // console.log('sortByImpressions after');
      // console.log(this.list);
    }
  },

  getViewedPackages: function(){
    var viewed = useCookie(this.cookieNameViewedPackages,{maxAge: 60 * 60 * 24 * 31 * 6}).value ?? '';
    viewed += '';
    if(!viewed)
      return [];
    if(viewed.indexOf(',') < 0)
      var ret = [viewed];
    else
      var ret = viewed.split(',');

    return ret.map((item) => {
      return Number(item);
    });
  },
  setViewedPackages: function(list){
    if(!list?.length)
      return;
    useCookie(this.cookieNameViewedPackages,{maxAge: 60 * 60 * 24 * 31 * 6}).value = list.join(',');
  },
  removeViewedPackage: function(id, list){
    return list.filter((item) => {
      return item != id;
    })
  },
  addViewedPackage: function(id){
    if(!id) return;
    id = Number(id);
    var viewed = this.getViewedPackages();
    // console.log('getViewedPackages', viewed);
    viewed = this.removeViewedPackage(id, viewed);
    // viewed.unshift(id);
    viewed.push(id);
    // console.log('getViewedPackages', viewed);
    this.setViewedPackages(viewed);
  },
  sortPackageByViews: function(packages){
    var viewed = this.getViewedPackages();
    if(!viewed.length)
      return packages;
    
    return packages.sort((a, b) => {
      // return  viewed.indexOf(a.id) - viewed.indexOf(b.id);
      // console.log('sort');
      // console.log(a.id);
      // console.log(viewed.indexOf(a.id));
      return  viewed.indexOf(a.id) - viewed.indexOf(b.id);
    })
  },
  getPackagesIds: function(packages){
    if(!packages?.length)
      return [];

    var ids = [];
    for(var itemPackage of packages){
      ids.push(itemPackage.id);
    }
    return ids;
  },
  flattenBanners: function(packages){

    if(!packages.length)
      return [];

    var forMax = JSON.parse(JSON.stringify(packages));
    var maxCountBannersInPackage = forMax.sort((a, b) => {
      return  b.banners.length - a.banners.length;
    })[0].banners.length;
    
    // console.log('maxCountBannersInPackage');
    // console.log(maxCountBannersInPackage);

    // fille ampty banners
    for(var itemPackage of packages){
      if(itemPackage.banners.length < maxCountBannersInPackage){
        for(var i = itemPackage.banners.length; i < maxCountBannersInPackage; i++){
          itemPackage.banners = [...itemPackage.banners, ...itemPackage.banners];
        }
        itemPackage.banners = itemPackage.banners.slice(0, maxCountBannersInPackage);
      }
    }


    // console.log('packages filled');
    // console.log(packages);
    

    var banners = []; 

    for(var i = 0; i < maxCountBannersInPackage; i++){
      for(var itemPackage of packages){
        if(itemPackage.banners[i]){
          banners.push(itemPackage.banners[i]);
        }
      }
    }

    // console.log('banners');
    // console.log(banners);



    return banners;
  },
  sortAndFilter: function(){

    var packages = JSON.parse(JSON.stringify(this.list));

    if(this.getSection() == 'index'){
      
      var banners = [];
      var boosted_banner_1 = null;
      var boosted_banner_2 = null;
      
      var boostedPackages = packages.filter((itemPackage) => {
        return itemPackage.boosted;
      });


      if(boostedPackages.length){

        

        boostedPackages.sort((a, b) => {
          return b.sys_impression - a.sys_impression;
        });
  

        boostedBanners = this.sortPackageByViews(boostedPackages);

        var boostedPackage = boostedPackages[0];

        if(boostedPackages.length && boostedPackage.banners.length){
          banners.push(boostedPackages[0].banners[0]);
          if(boostedPackages.length && boostedPackage.banners.length > 1){
            boosted_banner_2 = boostedPackage.banners[1];
          }else{
            boosted_banner_1 = boostedPackage.banners[0];
            // banners.push(boostedPackages[0].banners[0]);
          }
        }
      }


      var otherPackages = JSON.parse(JSON.stringify(packages));

      // console.log('otherPackages');
      // console.log(JSON.parse(JSON.stringify(otherPackages)));

      // var boostedPackagesIds = this.getPackagesIds(boostedPackages);
      
      // boostedPackages = boostedPackages.filter((itemPackage) => {
      //   return itemPackage.id != boostedPackage.id;
      // });

      // console.log('otherPackages');
      // console.log(JSON.parse(JSON.stringify(otherPackages)));

      otherPackages.sort((a, b) => {
        // return  b.sys_impression - a.sys_impression;
        return (a.sys_impression / a.boost) - (b.sys_impression / b.boost);
      });


      // console.log('boostedPackages');
      // console.log(boostedPackages);
      // console.log('otherPackages');
      // console.log(otherPackages);

      var boostedBanners = this.flattenBanners(boostedPackages);
      var otherBanners = this.flattenBanners(otherPackages);

      if(boosted_banner_1){
        banners.push(boosted_banner_1);
      }

      banners = [...banners, ...otherBanners];

      if(boosted_banner_2){
        banners.push(boosted_banner_2);
      }

      banners = [...banners, ...boostedBanners];

      // console.log('banners');
      // console.log(banners);

    }else{

      // console.log('packages before');
      // console.log(JSON.parse(JSON.stringify(packages)));

      packages.sort((a, b) => {
        // console.log('sort' + a.id + ' ' + b.id);
        // console.log('boost' + a.boosted + ' ' + b.boosted);
        // console.log('result' + (a.sys_impression / (a.boosted ? 2 : 1)) + ' ' + (b.sys_impression / (b.boosted ? 2 : 1)));
        return (a.sys_impression / a.boost) - (b.sys_impression / b.boost);
      });


      // console.log('packages after');
      // console.log(JSON.parse(JSON.stringify(packages)));


      var banners = this.flattenBanners(packages);



    }

    // console.log('banners');
    // console.log(banners);

    this.prepared = banners;
    return banners;
    
  }, 
  setList: function(){
    const { advList } = useAdvList();
    if(!advList.value?.items_cpm)
      return [];
    this.list = advList.value?.items_cpm;
  },
  getNextCpm: function(){
    // if(!this.prepared?.length){
    //   this.prepare();
    // }

    if(this.last_num > this.prepared.length - 1){
      this.last_num = 0;
    }
    var ret = this.prepared[this.last_num];

    this.last_num++;
    this.insert_count++;

    // console.log('ret.id');
    // console.log(this.list); 

    return ret;
  },
  prepare: function(){    
    this.setLastNum(0);
    this.setLastRow(0);
    // this.clearBox();
    this.sortAndFilter();
  },
  applyCpmWithRedraw: function(){
    this.applyCpmPlaceholder(true);
  },
  applyCpm: function(){
    if(this.isIgnoreSection())
      return false;
  
    if(this.isIgnorePages())
      return false;


    if(!this.isReadyCpmApply())
      return false
    
    this.applyCpmPlaceholder();
    this.applyCpmList();
    this.applyCpmList(['.comments']);
    
  },
  getCpmContainer: function(containers){ 
    var containers = containers ? containers : ['.feed_items', '.reviews_boxs', '.harvests_boxs', '.products_boxs','.brands_boxs', '.gallery_rows', '.report_boxs', '.comments', '.growers_boxs', '.catalog_content'];
    for(var c of containers){
      if(document.querySelector(c))
        return document.querySelector(c)
    }          
  },
  getCpmStepContainer: function(list){ 
    // var list = this.getCpmContainer();      

    var ret = {"step": 3, "count": 0, "items_class": "*"};
    if(this.getCpmCountInContainer(list,'video_item')){
      var cnt_row = this.getCpmCountInRowContainer(list,'video_item');
      ret.step = 3*cnt_row;
      ret.count = this.getCpmCountInContainer(list,'video_item');
      ret.items_class = '.video_item';
    }else if(this.getCpmCountInContainer(list,'row_item') && document.location.pathname == '/' && this.isMobile()){
      // alert(document.location.pathname);
      
      var cnt_row = this.getCpmCountInRowContainer(list,'row_item');
      ret.step = 4;
      ret.count = this.getCpmCountInContainer(list,'row_item');
      ret.items_class = '.row_item';
      // console.log('ret');
      // console.log(ret);
    }else if(this.getCpmCountInContainer(list,'report_box_item') && document.location.pathname.indexOf('explore')>=0 && this.isMobile()){
      
      var cnt_row = this.getCpmCountInRowContainer(list,'report_box_item');
      ret.step = 4*cnt_row-1;
      ret.count = this.getCpmCountInContainer(list,'report_box_item');
      ret.items_class = '.report_box_item';
    }else if(this.getCpmCountInContainer(list,'report_box_item') && !document.location.pathname.indexOf('explore') && !document.location.pathname.indexOf('/') ){        

      var cnt_row = this.getCpmCountInRowContainer(list,'report_box_item');
      ret.step = 3*cnt_row;
      ret.count = this.getCpmCountInContainer(list,'report_box_item');
      ret.items_class = '.report_box_item';
    }else if(this.getCpmCountInContainer(list,'review_item')){
      ret.step = this.isMobile() ? 6 : 6;
      ret.count = this.getCpmCountInContainer(list,'review_item');
      ret.items_class = '.review_item';
    }else if(this.getCpmCountInContainer(list,'comment')){
      // ret.step = 10;
      ret.step = this.isMobile() ? 7 : 7;
      ret.count = this.getCpmCountInContainer(list,'comment');
      ret.items_class = '.comment';
    }else if(this.getCpmCountInContainer(list,'solution_item')){
      // ret.step = 10;
      ret.step = this.isMobile() ? 7 : 7;
      ret.count = this.getCpmCountInContainer(list,'solution_item');
      ret.items_class = '.solution_item';

    }else if(this.getCpmCountInContainer(list,'row_item')){
      // ret.step = 10;
      ret.step = this.isMobile() ? 10 : 20;
      ret.count = this.getCpmCountInContainer(list,'row_item');
      ret.items_class = '.row_item';
    }else if(this.getCpmCountInContainer(list,'grower_item')){
      // ret.step = 10;
      ret.step = this.isMobile() ? 10 : 20;
      ret.count = this.getCpmCountInContainer(list,'grower_item');
      ret.items_class = '.grower_item';
    }else if(this.getCpmCountInContainer(list,'breeder_item')){
      // ret.step = 10;
      ret.step = this.isMobile() ? 10 : 20;
      ret.count = this.getCpmCountInContainer(list,'breeder_item');
      ret.items_class = '.breeder_item';
      // console.log(ret);
    }else if(this.getCpmCountInContainer(list,'harvest_row')){
      // ret.step = 10;
      ret.step = this.isMobile() ? 10 : 20;
      ret.count = this.getCpmCountInContainer(list,'harvest_row');
      ret.items_class = '.harvest_row';
    }else if(this.getCpmCountInContainer(list,'item')){
      // ret.step = 10;
      ret.step = this.isMobile() ? 10 : 20;
      ret.count = this.getCpmCountInContainer(list,'item');
      ret.items_class = '.item';
    }else if(this.getCpmCountInContainer(list,'event')){
      // ret.step = 20;
      ret.step = this.isMobile() ? 20 : 40;
      ret.count = this.getCpmCountInContainer(list,'event');
      ret.items_class = '.event';
    }
    return ret;

  },
  getCpmCountInContainer: function(list, item){ 
    return list.querySelector('.' + item) ? list.querySelectorAll('.' + item).length : 0;
  },
  getCpmCountInRowContainer: function(list, item){ 
    if(!list.querySelector('.' + item))
      return 0;
    var width = list.querySelector('.' + item).offsetWidth
    var width_list = list.offsetWidth;
    var count_in_row = Math.round(width_list / width);
    return count_in_row;      
  },
  getCpmNextAll: function(element, cl){ 
  
    const nextElements = []
    let nextElement = element    
    while(nextElement.nextElementSibling) {        
      if(nextElement.classList.contains(cl))
        nextElements.push(nextElement)                  
      nextElement = nextElement.nextElementSibling
    }    
    return nextElements;  

  },
  applyCpmList: function(containers){ 

    var containers = containers ?? null;

    // console.log('applyCpmList');

    var list = this.getCpmContainer(containers);
    
    if(!list)
      return false

    var append_boxes = list.querySelector('.cpm') ? list.querySelectorAll('.cpm').length : 0;
    var count_box = list.querySelector('.cpm') ? list.querySelectorAll('.cpm').length : 0;
    var steps = this.getCpmStepContainer(list);
    var ready_box = Math.floor(steps.count/steps.step);
    
    // console.log('list', list);
    
    // console.log('mount node cpm');
    // console.log(ready_box);
    // console.log(count_box);
    // console.log(steps);

    if(ready_box > count_box){      
      
      // console.log('cpm gooooo');

      for(var i = 0; i < ready_box - count_box; i++){
        var last_box_list = list.querySelectorAll('.cpm');
        var last_box = last_box_list ? last_box_list[last_box_list.length-1] : false;
        // last_box = last_box && last_box.nextSibling() ? last_box.nextSibling() : list.querySelector('* > ' + steps.items_class);
        last_box = last_box && last_box.nextElementSibling && last_box.nextElementSibling.classList.contains(steps.items_class.substr(1)) ? last_box.nextElementSibling : list.querySelector('* > ' + steps.items_class);
        
        // var after_box = last_box.nextAll().filter(function(){ return $(this).hasClass(steps.items_class.substr(1)) ? 1 : 0 }).eq(steps.step-1);
        var next_all = this.getCpmNextAll(last_box, steps.items_class.substr(1));
        var after_box = next_all[steps.step-1] ? next_all[steps.step] : last_box;

        if(next_all.length < steps.step){
          return false;
        }

        // console.log('after_box');
        // console.log(last_box);
        // console.log(next_all);
        // console.log(after_box);
        // console.log('-----after_box');
        // console.log('next_all[steps.step-1]');
        // console.log(next_all[steps.step-1]);
        // console.log(after_box);
        if(after_box){  

          var data_box = this.getNextCpm(); 
          
          // console.log('after_box');
          // console.log(after_box);
          // console.log(data_box);

          // let toastComp = nuxtApp.vueApp.extend(AdvCpm)
          // let html = new toastComp({
          //   propsData: {
          //     data: data_box,
          //     adv: this, 
          //   }
          // }).$mount();

          // import { createApp } from 'vue';
          // import AdvCpm from 'path/to/AdvCpm.vue';

          // const toastComp = createApp(AdvCpm);
          // const html = toastComp.mount('#app');

           
          let mountNode = document.createElement('div')
          mountNode.className = 'cpm' 

          var app = createApp(AdvCpmComponent,{
            data: data_box,
            adv: this,
          })
          // app.use(VueObserveVisibility);
          app.mount(mountNode) 

          

          // this.elements.push(html.$el);
          
          after_box.before(mountNode); 
        }

      }

    }
  },
  applyCpmPlaceholder: function(redraw){ 


    
    if(redraw !== 'undefined' && redraw){
      this.prepare();
      document.querySelectorAll('.cpm').forEach(function(el){
        el.classList.add('save-size');
      });
      document.querySelectorAll('.cpm .cpm_item').forEach(function(el){
        el.remove();
      });
      
      // console.log('applyCpmPlaceholder');
      // console.log(redraw);
      // console.log('this.list');
      // console.log(this.list);

    }


    var list = document.querySelectorAll('.cpm');
    for(var item of list){ 

      // console.log('applyCpmPlaceholder');
      // console.log(item);
      // console.log(item.querySelectorAll('.cpm_item'));
      // console.log('applyCpmPlaceholder');
      // console.log(item);

      if(item.querySelectorAll('.cpm_item').length){
        continue;
      }

      var data_box = this.getNextCpm(); 
           
      var app = createApp(AdvCpmComponent,{
        data: data_box,
        adv: this,
      })
      // app.use(VueObserveVisibility);
      app.mount(item) 
      
    } 
  },
   
}