
// import axios from "axios";
// import { defineNuxtPlugin } from "#app";
// import  configenv from "@/configs/alp.js";

import { appendResponseHeader, H3Event } from 'h3'

export default defineNuxtPlugin((nuxtApp) => {

  let api = {

    // RAW
    $fetchAsync: async function (url: string, options: Object = {}) {
      // return await useCustomFetch(url, options).data.value;
      let { data } = await useCustomFetch(url, options);
      return data.value;
    },
    $fetch: function (url: string, options: Object = {}) {
      // return useCustomFetch(url, options);

      return new Promise((resolve, reject) => {
        useCustomFetch(url, options)
        .then(response => {
          if (response.error.value) {
            reject((response.error.value));
          } else {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
      });

    },
    $fetchXMLHttp: function (url: string, options: Object = {}) {
      // return useCustomFetch(url, options);

      return new Promise((resolve, reject) => {
        const { data, error, loading, abort } = useCustomXMLHttpReq<T>(url, options);

        // Отслеживание изменений в ответе, ошибке и статусе загрузки
        watch([data, error, loading], () => {
          if (loading.value === false) { // Проверка, что загрузка завершена
            if (error.value) {
              reject(error.value); // Отклонение промиса при наличии ошибки
            } else {
              resolve(data.value); // Разрешение промиса при успешном получении данных
            }
          }
        }, { immediate: true });

        // Возвращаем функцию для возможности отмены запроса извне
        return { abort };
      });

    },
    rnd: function(){
      return Math.floor(Math.random()*100000000);
    },
    // global search

    getGlobalSearch: async function(query){
      const d = await this.$fetchAsync('/api/search', {
        params: {
          query: query
        }
      });
      return d.data;
    },
    getSearchBrandProduct: async function(query){
      const d = await this.$fetchAsync('/api/search/brandproduct', {
        params: query
      });
      return d.data;
    },

    // global search
    getExplore: async function(query){
      const d = await this.$fetchAsync('/api/diaries', {
        params: query
      });
      return d.data;
    },

    // global total count
    getDiariesTotalCount: async function(){
      const d = await this.$fetchAsync('/api/diaries/total', {
      });
      return d.data;
    },

    // diary
    getDiary: async function(id){
      const d = await this.$fetchAsync('/api/diaries/' + id);
      // d.data.addon = d.raise;
      return d.data;
      // return {data: d.data, addon: d.raise};
    },
    getDiaries: async function(params){
      const d = await this.$fetchAsync('/api/diaries', {
        params: params
      });
      return d.data;

    },
    getSimilarDiaries: async function(id){
      const d = await this.$fetchAsync('/api/diaries/' + id + '/similar-diaries');
      return d.data;

    },
    deleteDiary: function(id){
      const d = this.$fetch('/api/diaries/' + id, {
        method: 'DELETE'
      });
      return d;
    },
    postDiary: function(data){
      const d = this.$fetch('/api/diaries', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },
    postDiaryApprove: function(id, state){
      const d = this.$fetch('/api/diaries/' + id + '/approve', {
        method: 'POST',
        body: JSON.stringify({
          state: state
        })
      });
      return d;
    },
    postDiaryImpressions: function(id){
      const d = this.$fetch('/api/diaries/' + id + '/impressions', {
        method: 'POST'
      });
      return d;
    },
    patchDiary: function(id, data){
      const d = this.$fetch('/api/diaries/' + id, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
      return d;
    },
    putDiary: function(id, data){
      const d = this.$fetch('/api/diaries/' + id, {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      return d;
    },
    putDiaryCover: function(id, photo_id){
      const d = this.$fetch('/api/diaries/' + id + '/cover-week', {
        method: 'PUT',
        body: JSON.stringify({
          photo_id: photo_id
        })
      });
      return d;
    },
    getDiaryHarvestProductsReview: async function(id){
      const d = await this.$fetchAsync('/api/diaries/' + id + '/products-review');
      return d.data;
    },

    // week
    deleteWeek: function(diary_id, week_id){
      const d = this.$fetch('/api/diaries/' + diary_id + '/weeks/' + week_id, {
        method: 'DELETE'
      });
      return d;
    },
    postWeek: function(diary_id, data){
      const d = this.$fetch('/api/diaries/' + diary_id + '/weeks', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },
    putWeek: function(diary_id, week_id, data){
      const d = this.$fetch('/api/diaries/' + diary_id + '/weeks/' + week_id, {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      return d;
    },


    // user - grower
    getGrowers: async function(data){
      const d = await this.$fetchAsync('/api/growers', {
        params: data
      });
      return d.data;
    },

    getGrowerActivity: async function(id, data){
      const d = await this.$fetchAsync('/api/growers/' + id + '/activity', {
        params: data
      });
      return d.data;
    },



    postGrowers: function(data){
      const d = this.$fetch('/api/growers', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },

    pathMeasure: function(id, type, vl){
      if(!id) return;
      let data = {};
      data[type] = vl;
      const d = this.$fetch('/api/growers/' + id, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
      return d;
    },

    getEmailConfirmationToken: async function(k,e){
      const d = await this.$fetchAsync('/api/session/email-confirmation-token', {
        params: {
          k: k,
          e: e
        }
      });
      return d.data;
    },

    getEmailUnsubscribeToken: async function(k){
      const d = await this.$fetchAsync('/api/session/email-unsubscribe-token', {
        params: {
          k: k
        }
      });
      return d.data;
    },

    getForgotTokenState: async function(token){
      const d = await this.$fetchAsync('/api/session/forgot-token-state', {
        params: {
          token: token
        }
      });
      return d.data;
    },
    postForgotPasswordRequest: function(email, recaptcha){
      const d = this.$fetch('/api/session/forgot-password', {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          recaptcha: recaptcha
        })
      });
      return d;
    },
    postGrowerResendVerificationEmail: function(){
      const d = this.$fetch('/api/session/resend-verification', {
        method: 'POST',
        body: JSON.stringify({
        })
      });
      return d;
    },
    patchResetPassword: function(password, passwordRepeat, token){
      const d = this.$fetch('/api/session/reset-password', {
        method: 'PATCH',
        body: JSON.stringify({
          password: password,
          password_repeat: passwordRepeat,
          token: token
        })
      });
      return d;
    },
    getGrower: async function(id){
      const d = await this.$fetchAsync('/api/growers/' + id);
      return d.data;
    },
    getGrowerByName: async function(name){
      const d = await this.$fetchAsync('/api/ref/growers/' + name);
      return d.data;
    },
    getGrowerUpdates: async function(id){
      const d = await this.$fetchAsync('/api/growers/' + id + '/updates');
      return d.data;
    },
    getGrowerInfo: async function(id){
      const d = await this.$fetchAsync('/api/growers/' + id + '/information');
      return d.data;
    },
    getGrowerPresetsExplore: async function(id){
      const d = await this.$fetchAsync('/api/growers/' + id + '/presets/explore');
      return d.data;
    },
    deleteGrowerPresetsExplore: function(user_id, id){
      const d = this.$fetch('/api/growers/' + user_id + '/presets/explore/' + id, {
        method: 'DELETE'
      });
      return d;
    },
    postGrowerPresetsExplore: async function(user_id, data){
      const d = await this.$fetch('/api/growers/' + user_id + '/presets/explore', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d.data;
    },

    getGrowerSettings: async function(id, tab){
      const d = await this.$fetchAsync('/api/growers/' + id + '/settings' + (tab ? ('/' + tab) : ''));
      return d.data;
    },
    putGrowerProfile: function(id, data){
      const d = this.$fetch('/api/growers/' + id, {
        method: 'PUT',
        body: JSON.stringify(data)
      });
      return d;
    },
    patchProfilePassword: function(id, data){
      const d = this.$fetch('/api/growers/' + id, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
      return d;
    },
    patchProfileCookie: function(id, data){
      const d = this.$fetch('/api/growers/' + id, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
      return d;
    },
    postProfilePersonalWithPassword: function(id, data){
      const d = this.$fetch('/api/growers/' + id + '/settings/personal/access', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },

    postProfilePersonal: function(game_id, data){
      const d = this.$fetch('/api/growers/' + game_id + '/settings/personal', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },
    deleteProfilePersonal: function(id){
      const d = this.$fetch('/api/growers/' + id + '/settings/personal', {
        method: 'DELETE'
      });
      return d;
    },
    deleteProfileBanned: function(id, type, content_id){
      const d = this.$fetch('/api/growers/' + id + '/settings/banned/' + type + '/' + content_id, {
        method: 'DELETE'
      });
      return d;
    },
    deleteProfileAccount: function(id){
      const d = this.$fetch('/api/growers/' + id, {
        method: 'DELETE'
      });
      return d;
    },
    getGrowerFollowing: async function(id){
      const d = await this.$fetchAsync('/api/growers/' + id + '/following');
      return d.data;
    },
    getGrowerFollowingCommon: async function(id, start, limit, sort){
      const d = await this.$fetchAsync('/api/growers/' + id + '/following/common', {
        params: {
          start: start,
          limit: limit,
          sort: sort,
        }
      });
      return d.data;
    },
    getGrowerFollowingBookmark: async function(id, start, limit, sort){
      const d = await this.$fetchAsync('/api/growers/' + id + '/following/bookmark', {
        params: {
          start: start,
          limit: limit,
          sort: sort,
        }
      });
      return d.data;
    },

    postUploaderGrowerAvatar: function(formData){
      const d = this.$fetch('/api/uploads/growers/avatars', {
        method: 'POST',
        domain: 'UPLOAD',
        body: formData,
      });
      return d;
    },

    // video
    getVideo: async function(id){
      const d = await this.$fetchAsync('/api/videos/' + id);
      return d.data;
    },

    // problems
    getQuestions: async function(data){
      const d = await this.$fetchAsync('/api/problems', {
        params: data
      });
      return d.data;
    },
    getQuestionsDiary: async function(id, data){
      const d = await this.$fetchAsync('/api/problems/diary/' + id, {
        params: data
      });
      return d.data;
    },
    getQuestion: async function(id){
      const d = await this.$fetchAsync('/api/problems/' + id);
      return d.data;
    },
    postQuestion: function(data){
      const d = this.$fetch('/api/problems', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },
    patchQuestion: function(id, data){
      const d = this.$fetch('/api/problems/' + id, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
      return d;
    },
    deleteQuestion: function(id){
      const d = this.$fetch('/api/problems/' + id, {
        method: 'DELETE',
      });
      return d;
    },
    postQuestionSolution: function(question_id, data){
      const d = this.$fetch('/api/problems/' + question_id + '/solution', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },
    patchQuestionSolution: function(question_id, solution_id, data){
      const d = this.$fetch('/api/problems/' + question_id + '/solution/' + solution_id, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
      return d;
    },

    deleteQuestionSolution: function(question_id, solution_id){
      const d = this.$fetch('/api/problems/' + question_id + '/solution/' + solution_id, {
        method: 'DELETE',
      });
      return d;
    },

    // shorts
    getShorts: async function(data){
      const d = await this.$fetchAsync('/api/shorts', {
        params: data
      });
      return d.data;
    },

    // comment
    getComments: async function(id, type, start, limit, sort, preloadLnk){
      const d = await this.$fetchAsync('/api/comments/' + type + '/' + id, {
        params: {
          start: start,
          limit: limit,
          sort: sort,
          preload_comment: preloadLnk
        }
      });
      return d.data;
    },
    getCommentsReplies: async function(content_id, type, id, start, limit, sort){
      const d = await this.$fetchAsync('/api/comments/' + type + '/' + content_id + '/' + id + '/replies', {
        params: {
          start: start,
          limit: limit,
          sort: sort
        }
      });
      return d.data;
    },
    postComment: function(s_type, content_id, data){
      const d = this.$fetch('/api/comments/' + s_type + '/' + content_id, {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },
    pathComment: function(id, s_type, content_id, data){
      const d = this.$fetch('/api/comments/' + s_type + '/' + content_id + '/' + id, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
      return d;
    },
    deleteComment: function(id, s_type, content_id){
      const d = this.$fetch('/api/comments/' + s_type + '/' + content_id + '/' + id, {
        method: 'DELETE'
      });
      return d;
    },

    // like
    postToggleLike: function(type, id){
      const d = this.$fetch('/api/likes/' + type + '/' + id, {
        method: 'POST'
      });
      return d;
    },
    postLike: function(type, id){
      const d = this.$fetch('/api/likes/' + type + '/' + id, {
        method: 'POST'
      });
      return d;
    },
    deleteLike: function(type, id){
      const d = this.$fetch('/api/likes/' + type + '/' + id, {
        method: 'DELETE'
      });
      return d;
    },
    // follow
    postToggleFollow: function(type, id){
      const d = this.$fetch('/api/follows/' + type + '/' + id, {
        method: 'POST'
      });
      return d;
    },
    postFollow: function(type, id){
      const d = this.$fetch('/api/follows/' + type + '/' + id, {
        method: 'POST'
      });
      return d;
    },
    deleteFollow: function(type, id){
      const d = this.$fetch('/api/follows/' + type + '/' + id, {
        method: 'DELETE'
      });
      return d;
    },
    patchFollow: function(type, id){
      const d = this.$fetch('/api/follows/' + type + '/' + id, {
        method: 'PATCH'
      });
      return d;
    },

    // complain
    postComplain: function(type, id){
      const d = this.$fetch('/api/complains/' + type + '/' + id, {
        method: 'POST'
      });
      return d;
    },

    // feed
    getFeed: async function(data){

      const d = await this.$fetchAsync('/api/feed', {
        params: data,
      });
      // return d;

      return d.data;
    },

    // chat
    getChatRooms: async function(start, limit){
      const d = await this.$fetchAsync('/api/chats', {
        params: {
          start: start,
          limit: limit,
        }
      });
      return d.data;
    },
    getChatCommonRooms: async function(start, limit){
      const d = await this.$fetchAsync('/api/chats/common', {
        params: {
          start: start,
          limit: limit,
        }
      });
      return d.data;
    },
    getChatBusinessRooms: async function(start, limit){
      const d = await this.$fetchAsync('/api/chats/business', {
        params: {
          start: start,
          limit: limit,
        }
      });
      return d.data;
    },
    postChatRoom: function(id){
      const d = this.$fetch('/api/chats', {
        method: 'POST',
        body: JSON.stringify({
          user_id: id
        })
      });
      return d;
    },
    getAddressRoom: function(id){
      const d = this.$fetch('/api/chats/address/' + id, {
        method: 'GET'
      });
      return d;
    },
    postChatRoomUser: function(room_id, user_id){
      const d = this.$fetch('/api/chats/' + room_id + '/users', {
        method: 'POST',
        body: JSON.stringify({
          user_id: user_id
        })
      });
      return d;
    },
    deleteChatRoomUser: function(room_id, user_id){
      const d = this.$fetch('/api/chats/' + room_id + '/users', {
        method: 'DELETE',
        body: JSON.stringify({
          user_id: user_id
        })
      });
      return d;
    },
    deleteChatRoom: function(id){
      const d = this.$fetch('/api/chats/' + id, {
        method: 'DELETE'
      });
      return d;
    },
    patchChatRoom: function(id, data){
      const d = this.$fetch('/api/chats/' + id, {
        method: 'PATCH',
        body: JSON.stringify(data)
      });
      return d;
    },
    getChatRoomMessages: async function(id, first, last, older, address){
      const d = await this.$fetchAsync('/api/chats/' + id, {
        params: {
          first: first,
          last: last,
          older: older,
          address: address
        }
      });
      return d.data;
    },
    getChatRoomUsers: async function(id, start, limit){
      const d = await this.$fetchAsync('/api/chats/' + id + '/users', {
        params: {
          start: start,
          limit: limit,
        }
      });
      return d.data;
    },
    getChatRoomUsersSearch: async function(id, text, start, limit){
      const d = await this.$fetchAsync('/api/chats/' + id + '/users-search', {
        params: {
          start: start,
          limit: limit,
          search: text
        }
      });
      return d.data;
    },
    postChatRoomMessage: function(id, text, address){
      const d = this.$fetch('/api/chats/' + id + '/messages', {
        method: 'POST',
        body: JSON.stringify({
          text: text,
          address: address
        })
      });
      return d;
    },
    postChatRoomBan: function(id){
      const d = this.$fetch('/api/chats/' + id + '/bans', {
        method: 'POST',
      });
      return d;
    },
    postChatRoomCompain: function(id){
      const d = this.$fetch('/api/chats/' + id + '/complains', {
        method: 'POST',
      });
      return d;
    },

    // adv
    postAdvViewBox: function(id, section){
      const d = this.$fetch('/api/adv/box/view/' + id, {
        method: 'POST',
        body: JSON.stringify({
          section: section
        })
      });
      return d;
    },
    postAdvViewCpm: function(id, section){
      const d = this.$fetch('/api/adv/cpm/view/' + id, {
        method: 'POST',
        body: JSON.stringify({
          section: section
        })
      });
      return d;
    },
    postAdvViewSidebar: function(id, section){
      const d = this.$fetch('/api/adv/sidebar/view/' + id, {
        method: 'POST',
        body: JSON.stringify({
          section: section
        })
      });
      return d;
    },
    sendOneRowAdvStat: function(data){
      const d = this.$fetch('/api/adv/stat' + '?rand=' + this.rnd() , {
        method: 'GET',
        query: data
      });
      return d;
    },
    postAdvStat: function(data){
      const d = this.$fetch('/api/adv/stat' + '?rand=' + this.rnd() , {
        method: 'POST',
        body: JSON.stringify({
          stat: data
        })
      });
      return d;
    },
    postAdvViewHeader: function(id, section){
      const d = this.$fetch('/api/adv/header/view/' + id, {
        method: 'POST',
        body: JSON.stringify({
          section: section
        })
      });
      return d;
    },
    postAdvClickBox: function(id, section){
      const d = this.$fetch('/api/adv/box/click/' + id, {
        method: 'POST',
        body: JSON.stringify({
          section: section
        })
      });
      return d;
    },
    postAdvClickCpm: function(id, section){
      const d = this.$fetch('/api/adv/cpm/click/' + id, {
        method: 'POST',
        body: JSON.stringify({
          section: section
        })
      });
      return d;
    },
    postAdvClickSidebar: function(id, section){
      const d = this.$fetch('/api/adv/sidebar/click/' + id, {
        method: 'POST',
        body: JSON.stringify({
          section: section
        })
      });
      return d;
    },
    postAdvClickHeader: function(id, section){
      const d = this.$fetch('/api/adv/header/click/' + id, {
        method: 'POST',
        body: JSON.stringify({
          section: section
        })
      });
      return d;
    },
    getAdvAll: async function(){
      const d = await this.$fetchAsync('/api/adv');
      return d.data;
    },
    getAdvAllSections: async function(){
      const d = await this.$fetchAsync('/api/adv/fully');
      return d.data;
    },
    getAdvHeader: async function(section){
      const d = await this.$fetchAsync('/api/adv/header', {
        params: {
          s: section
        }
      });
      return d.data;
    },
    getAdvBox: async function(){
      const d = await this.$fetchAsync('/api/adv/box');
      return d.data;
    },

    // journal
    getJournals: async function(start, limit){
      const d = await this.$fetchAsync('/api/journal', {
        params: {
          start: start,
          limit: limit
        }});
      return d.data;
    },
    postJournalVote: function(id){
      const d = this.$fetch('/api/journal/votes/' + id + '/vote', {
        method: 'POST'
      });
      return d;
    },
    getJournal: async function(id){
      const d = await this.$fetchAsync('/api/journal/' + id);
      return d.data;
    },
    getJournalCategoryList: async function(start, limit, id){
       const d = await this.$fetchAsync('/api/journal/category/' + id, {
        params: {
          start: start,
          limit: limit
        }});
      return d.data;
    },
    getJournalTagList: async function(start, limit, id){
       const d = await this.$fetchAsync('/api/journal/tag/' + id, {
        params: {
          start: start,
          limit: limit
        }});
      return d.data;
    },
    getJournalVoteList: async function(start, limit){
       const d = await this.$fetchAsync('/api/journal/voting', {
        params: {
          start: start,
          limit: limit
        }});
      return d.data;
    },
    getJournalVote: async function(id){
      const d = await this.$fetchAsync('/api/journal/voting/' + id);
      return d.data;
    },

    // notification
    getNotifications: async function(user, start, limit, is_visit){
       const d = await this.$fetchAsync('/api/growers/' + user + '/notifications', {
        params: {
          start: start,
          limit: limit,
          is_visit: is_visit ? 1 : 0
        }});
      return d.data;
    },
    getNotificationsUsers: async function(user, id, start, limit){
       const d = await this.$fetchAsync('/api/growers/' + user + '/notifications/' + id + '/users', {
        params: {
          start: start,
          limit: limit,
        }});
      return d.data;
    },

    // brand

    getBrands: async function(query){
      const d = await this.$fetchAsync('/api/brands', {
        params: query
      });
      return d.data;
    },

    getBrand: async function(id){
      const d = await this.$fetchAsync('/api/brands/' + id);
      return d.data;
    },

    getBrandBySection: async function(section){
      const d = await this.$fetchAsync('/api/ref/brands/' + section);
      return d.data;
    },

    getBrandBySectionCategory: async function(section, category){
      const d = await this.$fetchAsync('/api/ref/brands/' + section + '?category=' + category);
      return d.data;
    },

    getStaticPage: async function(link){
      const d = await this.$fetchAsync('/api/' + link);
      return d.data;
    },
    // contact
    postContact: function(data){
      const d = this.$fetch('/api/contacts', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },
    // giveaways
    getGiveaways: async function(start, limit, tags){
      let d = await this.$fetchAsync('/api/giveaways', {
        params: {
          start: start,
          limit: limit,
          tags: tags
        }});
      return d.data;
    },
    getGiveaway: async function(id){
      const d = await this.$fetchAsync('/api/giveaways/' + id);
      return d.data;
    },
    postGiveawayVote: function(game_id, category_id, contest_id){
      const d = this.$fetch('/api/giveaways/' + game_id + '/vote', {
        method: 'POST',
        body: JSON.stringify({
          category_id: category_id,contest_id: contest_id
        })
      });
      return d;
    },
    getGiveawayUserChooseDiary: async function(game_id, category_id){
      const d = await this.$fetchAsync('/api/giveaways/' + game_id + '/join/' + category_id);
      return d.data;
    },
    getGiveawayUserChoosePhotoVideoDiary: async function(game_id, category_id){
      const d = await this.$fetchAsync('/api/giveaways/' + game_id + '/join/' + category_id);
      return d.data;
    },
    getGiveawayUserChoosePhotoVideoDiaryContent: async function(game_id, category_id, content_id){
      const d = await this.$fetchAsync('/api/giveaways/' + game_id + '/join/' + category_id + '/' + content_id);
      return d.data;
    },
    postGiveawayJoin: function(game_id, category_id, content_id){
      const d = this.$fetch('/api/giveaways/' + game_id + '/join', {
        method: 'POST',
        body: JSON.stringify({
          category_id: category_id,content_id: content_id
        })
      });
      return d;
    },
    postGiveawayWithdraw: function(game_id){
      const d = this.$fetch('/api/giveaways/' + game_id + '/withdraw', {
        method: 'POST'
      });
      return d;
    },
    // partner
    postCheckoutPartner: function(data){
      const d = this.$fetch('/api/partners/checkout', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },
    // strains
    getStrains: async function(data){
      const d = await this.$fetchAsync('/api/strains', {
        params: data
      });
      return d.data;
    },
    getProducts: async function(data){
      const d = await this.$fetchAsync('/api/products', {
        params: data
      });
      return d.data;
    },

    // uploaders

    postMedia: function(formData, onprogress, u){
      let url = u || '/uploads/weeks/media';
      let d = this.$fetchXMLHttp('/api' + url + '?rand=' + this.rnd() , {
        method: 'POST',
        domain: 'UPLOAD',
        body: formData,
        onUploadProgress: onprogress
      });
      return d;
    },
    postRotatePhoto: function(id, angle){
      let d = this.$fetch('/api/uploads/rotate/' + id, {
        method: 'POST',
        domain: 'UPLOAD',
        body: {
          angle: angle
        },
      });
      return d;
    },
    postUploaderDiaryCover: function(formData){
      const d = this.$fetch('/api/uploads/diaries/covers', {
        method: 'POST',
        domain: 'UPLOAD',
        body: formData,
      });
      return d;
    },

    // strains
    getUrl: async function(url: string, data: object){
      const d = await this.$fetchAsync('/api' + url,  {
        params: data
      });
      return d.data;
    },
    getUrlSource: async function(url: string, data: object){
      const d = await this.$fetchAsync('/api' + url,  {
        params: data
      });
      return d;
    },
    getTest: async function(url: string, data: object){
      const d = await this.$fetchAsync('' + url,  {
        params: data
      });
      return d.data;
    },


    // b2b
    getB2BData: async function(){
      const d = await this.$fetchAsync('/api/brands-manager');
      return d.data;
    },

    getB2BInfo: async function(){
      const d = await this.$fetchAsync('/api/brands-manager/information');
      return d.data;
    },

    getB2BSearchAccounts: async function(query: string){
      const d = await this.$fetchAsync('/api/brands-manager/information/search',{
        params: {query: query}
      });
      return d.results;
    },

    postB2BAddAccount: async function(data){
      const d = this.$fetch('/api/brands-manager/information/add_manager', {
        method: 'POST',
        body: data
      });
      return d;
    },

    postB2BRemoveAccount: async function(data){
      const d = this.$fetch('/api/brands-manager/information/remove_manager', {
        method: 'POST',
        body: data
      });
      return d;
    },

    getB2BEdit: async function(){
      const d = await this.$fetchAsync('/api/brands-manager/edit');
      return d.data;
    },

    getB2BUploadAvatar: async function(data){
      const d = await this.$fetchAsync('/api/brands-manager/uploads/photo', {
        method: 'POST',
        body: data,
      });
      return d?d.data:null;
    },

    postB2BSaveBrand: async function(data){
      const d = this.$fetch('/api/brands-manager/edit', {
        method: 'POST',
        body: data
      });
      return d;
    },

    getB2BProducts: async function(){
      const d = await this.$fetchAsync('/api/brands-manager/products');
      return d.data;
    },

    deleteB2BProduct: async function(id,tmp_id){
      const d = this.$fetch('/api/brands-manager/products/' + id + (tmp_id ? '?tmp_id=' + tmp_id : ''), {
        method: 'DELETE'
      });
      return d;
    },

    getB2BProduct: async function(id,tmp_id){
      const d = await this.$fetchAsync('/api/brands-manager/products/edit/' + id + (tmp_id ? '?tmp_id=' + tmp_id : ''));
      return d.data;
    },

    postB2BSaveProduct: function(id,data){
      const d = this.$fetch('/api/brands-manager/products/edit/' + id, {
        method: 'POST',
        body: data
      });
      return d;
    },

    getB2BProductComposition: async function(id,tmp_id){
      const d = await this.$fetchAsync('/api/brands-manager/products/composition/edit/' + id + (tmp_id ? '?tmp_id=' + tmp_id : ''));
      return d.data;
    },

    postB2BSaveProductComposition: async function(id,data){
      const d = this.$fetch('/api/brands-manager/products/composition/edit/' + id, {
        method: 'POST',
        body: data
      });
      return d;
    },

    getB2BSchemes: async function(){
      const d = await this.$fetchAsync('/api/brands-manager/schemes');
      return d.data;
    },

    deleteB2BScheme: async function(id,tmp_id){
      const d = this.$fetch('/api/brands-manager/schemes/' + id + (tmp_id ? '?tmp_id=' + tmp_id : ''), {
        method: 'DELETE'
      });
      return d;
    },

    getB2BProductScheme: async function(id,tmp_id){
      const d = await this.$fetchAsync('/api/brands-manager/schemes/edit/' + id + (tmp_id ? '?tmp_id=' + tmp_id : ''));
      return d.data;
    },

    postB2BSaveProductScheme: async function(id,data){
      const d = this.$fetch('/api/brands-manager/schemes/edit/' + id, {
        method: 'POST',
        body: data
      });
      return d;
    },

    getB2BBanners: async function(){
      const d = await this.$fetchAsync('/api/brands-manager/banners');
      return d.data;
    },

    getB2BBannerStatistic: async function(id, type, data){
      const d = await this.$fetchAsync('/api/brands-manager/banners/' + id + '/' + type + '/statistic', {
        params: data
      });
      return d.data;
    },

    getB2BBannerStatisticPackage: async function(data){
      const d = await this.$fetchAsync('/api/brands-manager/banners/statistic', {
        params: data
      });
      return d.data;
    },

    deleteB2BBanner: async function(id,tmp_id,type){
      const d = this.$fetch('/api/brands-manager/banners/' + id + '/' + type + (tmp_id ? '?tmp_id=' + tmp_id : ''), {
        method: 'DELETE'
      });
      return d;
    },

    postBannerEnable: async function(id,type,data){
      const d = this.$fetch('/api/brands-manager/banners/' + id + '/' + type + '/enable', {
        method: 'POST',
        body: data
      });
      return d;
    },

    getB2BBanner: async function(id,tmp_id,type){
      const d = await this.$fetchAsync('/api/brands-manager/banners/' + id + '/' + type + '/edit' + (tmp_id ? '?tmp_id=' + tmp_id : ''));
      return d.data;
    },

    postB2BSaveBanner: async function(data,type){
      const d = this.$fetch('/api/brands-manager/banners/' + data.id + '/' + type + '/edit' + (data.tmp_id ? '?tmp_id=' + data.tmp_id : ''), {
        method: 'POST',
        body: data
      });
      return d;
    },

    getB2BChartNewsletters: async function(){
      const d = await this.$fetchAsync('/api/brands-manager/newsletters');
      return d.data;
    },

    getB2BChartNewsletterChart: async function(id){
      const d = await this.$fetchAsync('/api/brands-manager/newsletters/' + id + '/chart');
      return d.data;
    },

    getB2BStatistics: async function(){
      const d = await this.$fetchAsync('/api/brands-manager/statistics');
      return d.data;
    },

    getB2BSubscription: async function(){
      const d = await this.$fetchAsync('/api/brands-manager/subscription');
      return d.data;
    },


    getTranslate: async function(id, type, lang){
      const d = await this.$fetchAsync('/api/translate', {
        params: {
          id: id,
          type: type,
          lang: lang
        }
      });
      return d.data;
    },

    getWiki: async function(size, type, id){
      const d = await this.$fetchAsync('/api/wiki/' + type + '/' + id + '/' + size, {
      });
      return d.data;
    },

    postErrorNx: function(data){
      const d = this.$fetch('/api/errors/nx', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      return d;
    },

    serviceRescreen: function(data){
      const d = this.$fetch('/api/service/rescreen-videos', {
        method: 'GET',
        params: data
      });
      return d;
    },
  }

  return {
    provide: {
      api: api,
    },
  };
})