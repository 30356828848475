<template>
  
  <div class="cpm_item" 
    v-if="props.data?.id" 
    @click="click">

    <div class="adv-visibility" ref="elementObserver"></div>
    
    <a target="_blank" :data-id="props.data.id" data-position="" :href="props.data.redirect">
      <img data-no-lazy alt="advice" class="img" :src="props.data.url"/>
    </a>

  </div> 
  
</template>


<script setup>

const { $observer } = useNuxtApp()

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  adv: {
    type: Object,
    default: () => ({})
  },
})
const viewed = ref(false);
const elementObserver = ref(null);

const appear = (res) => {
  if(!viewed.value && res)        
    advCpm.viewCpm(props.data.id)  
  viewed.value = true;
}

const click = () => {
  advCpm.clickCpm(props.data.id)
  console.log('clicked');

}
 
onMounted(() => {
  $observer(elementObserver.value, (element) => {
    appear(true);
  });
});

</script>
  

<style scoped>
/* CPM */
.cpm_bx{
  min-height: 255px;
}
.cpm_item {
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-bottom: 10px;
    width: fit-content;
    margin: 10px auto;
}

.cpm_item img {
  border-radius: 3px;
  width: 100%;
  max-width: 300px;
  aspect-ratio: 300/250;
}

.cpm_item .turn_off {
    display: block;
    color: gray;
    font-size: 0.85rem;
    text-decoration: underline;
}
  
</style>
