import CryptoJS from 'crypto-js'

export default defineNuxtPlugin(async nuxtApp => {  

  const { $api } = useNuxtApp();

    var adv = {

      loaded: false, 
      timer: null,
      sendStat: async function(){  
        var stat = useAdvStat();
        
        if(!stat.value?.length){
          return false;
        }

        // for (let index = 0; index < stat.value.length; index++) {
        //   const item = stat.value[index];
        //   var data = {
        //     i: item.id,
        //     t: item.type,
        //     s: item.section,
        //     a: item.action,
        //     h: CryptoJS.AES.encrypt(item.id + item.type + item.section + item.action, useAuth().getGuest(), {iv: ''}).toString()
        //   };

        //   // console.log(data);
        //   await useNuxtApp().$api.sendOneRowAdvStat(data);
        //   stat.value.splice(index, 1);
        // }

        var data = stat.value.map((item) => {
 
          return {
            i: item.id,
            t: item.type,
            s: item.section,
            a: item.action,
            h: CryptoJS.AES.encrypt(item.id + item.type + item.section + item.action, useAuth().getGuest(), {iv: ''}).toString()
          };
        });


        stat.value = [];
        useNuxtApp().$api.postAdvStat(data);    
      },
      apply: async function(){  
        advCpm.applyCpm();
        advBox.applyBox()
        // advSidebar.applySidebar() 
      },  
      applyTimer: null,
      clearApplyTimer: function(){
        if(adv.applyTimer){
          clearTimeout(adv.applyTimer);
          adv.applyTimer = null
        }
      },
    }
 
  

    
      const { add, advList } = useAdvList();
      const { pending: is_loading,  data: adv_data } = await useLazyAsyncData('adv_data', async () => await $api.getAdvAll())

      add(adv_data?.value);
  

      advHeader.setList();
      advBox.setList();
      advCpm.setList();

      // console.log('adv_data?.value');
      // console.log(adv_data?.value);

      if(import.meta.client){ 

        // nuxtApp.hook('page:ready', () => {
        //   console.log('page:finish');
        //   nextTick(() => {
        //     // console.log('page:finish:nextTick');
        //     adv.apply();
        //   });
        // }) 

        // watch(() => router.currentRoute.value.fullPath, () => {
        //   console.log('router.currentRoute.value.fullPath');
        //   adv.apply();
        // })

        nuxtApp.hook('page:finish', () => {
          // console.log(advHeader);
          // console.log('page:finish');

          adv.clearApplyTimer();

          adv.applyTimer = setTimeout(() => {
            
            advBox.prepare();
            advBox.applyBox();

            advCpm.prepare();
            advCpm.applyCpm();
            // adv.apply();
            adv.clearApplyTimer();
          },500)
        }) 

        nuxtApp.hook('custom:fetch:get', () => {
          if(adv.applyTimer) return;

          adv.applyTimer = setTimeout(() => {
            adv.apply();
            adv.clearApplyTimer();
          }, 200);

        });


        if(adv.timer){
          clearInterval(adv.timer);
        }

        // setTimeout(() => {
        //   adv.sendStat();
        // }, 1000);

        adv.timer = setInterval(() => {
          adv.sendStat();
        }, 10000);


        window.addEventListener("beforeunload", function(event) {
          adv.sendStat();
        });

      

      }
 

  return {
    provide: {
      adv: adv,
    },
  };

})