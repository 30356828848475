
import { useScrollLock } from '@vueuse/core'

export default defineNuxtPlugin(nuxtApp => {  

  var noscroll = {
    on() {
      const isLocked = useScrollLock(document.body);
      isLocked.value = true;
      // document.body.style.overflow = 'hidden';
    },
    off() {
      const isLocked = useScrollLock(document.body);
      isLocked.value = false;
      if(document?.body)
        document.body.style.overflow = '';
    },
  }
   
  return {
    provide: {
      noscroll: noscroll,
    },
  };

})