


export default defineNuxtPlugin(nuxtApp => {  


var vartpl = {};

vartpl.user = {
  id: null,
  name: null,
  link: null,
  status: null,
  avatar: null,
  avatar_small: null,
  avatar_little: null,
}

vartpl.photo = {
  id: null,
  comment: null,
  size_s: null,
  size_m: null,
  size_xl: null,
  size_big: null,
}

vartpl.item_germination = {
  germ_method: {
    id: null
  },
  items_seed: [],
  items_nutrient: [],
}

vartpl.item_vegetation = {  
  items_nutrient: [],
  items_method: [],
}


vartpl.item_brand = {
  id: null,
  name: null,
  section: null,
  link: null, 
  avatar: null,
  avatar_small: null,
  avatar_little: null,
}

vartpl.item_product = {
  id: null,
  name: null,
  section: null,
  link: null,
  item_brand: vartpl.item_brand,
}

vartpl.item_review = {
  id: null,
  ref_id: null,
  text: null,
  n_general: null,
  props: {},
  item_product: vartpl.item_product,
  item_btand: vartpl.item_brand,
  items_review: [],
}

vartpl.item_harvest = {
  item_review_seed: vartpl.item_review,  
  items_review: [], 
}


vartpl.week = {
  id: null,
  add_date: null,
  update_date: null,
  days: null,
  faza: null,
  text: null,
  cnt_comments: null,
  cnt_likes: null,
  is_remove: null,
  is_video: null,
  items_photo: [],
  item_germination: vartpl.item_germination, 
  item_vegetation: vartpl.item_vegetation, 
  item_flowering: vartpl.item_vegetation, 
  item_harvest: vartpl.item_harvest, 
  props: {
    germ_date: '',
    height: null,
    ph: null,
    ppm: null,
    air_hum: null,
    co2: null,
    light: null,
    smell: null,
    air_temp: null,
    solution_temp: null,
    night_air_temp: null,
    substrate_temp: null,
    pot_size: null,
    lamp_distance: null,
    light_intensity: null,
    watering_volume: null,
    feeding_schedule: null,   
    harvest_weight: null, 
    harvest_wet_weight: null, 
    harvest_plant: null, 
    harvest_watt: null, 
    harvest_space: null, 
    harvest_smoke: null, 
    harvest_tasties: null,
    harvest_effects_positive: null,
    harvest_effects_negative: null,
    harvest_effects_medical: null,
  }
}

vartpl.diary = { 
  id: null,
  name: null,
  link: null,
  add_date: null,
  update_date: null,
  avatar: null,
  avatar_small: null,
  avatar_little: null,
  max_faza: null,
  is_harvest: null,
  is_video: null,
  n_progress_head: null,
  n_progress: null,
  locale: null,
  cnt_days: null,
  cnt_views: null,
  cnt_seeds: null,
  is_ban: null,
  is_moderate: null,
  date_active: null,
  is_hidden: null,
  is_remove: null,
  is_verify: null,
  is_lock: null,
  cnt_likes: null,
  cnt_followers: null,
  cnt_comments: null,
  type_room: null,
  type_watering: null,
  approved: [],
  items_seed: [],
  items_nutrient: [],
  items_lamp_veg: [],
  items_lamp_flo: [],
  items_tent: [],
  items_medium: [],
  item_user: vartpl.user,
  items_week: [],    

};


vartpl.question = {
  id: null,
  title: null,
  text: null,
  items_problem_photo: [],
  items_problem_symptom: [],
  item_diary: {},
  item_week: {},
}

vartpl.solution = {
  id: null, 
  text: null, 
  item_user: vartpl.user
}


 

  return {
    provide: {
      vartpl: vartpl,
    },
  };

})
