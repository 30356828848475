import type { UseFetchOptions } from 'nuxt/app'
import { defu } from 'defu'
import { appendResponseHeader, H3Event } from 'h3'



export function useCustomFetch<T> (url: string, options: UseFetchOptions<T> = {}) {

  
  const getCookieString = () => {
    const authGuest = useCookie('auth:guest',{maxAge: 60 * 60 * 24 * 31 * 6});
    const unitVolume = useCookie('unit_volume');
    const unitTemperature = useCookie('unit_temperature');
    const unitWeight = useCookie('unit_weight');
    const unitTds = useCookie('unit_tds');
    const unitLength = useCookie('unit_length');
    const details = useCookie('details');
    var string = '';

    if(unitVolume.value)
      string += `unit_volume=${unitVolume.value}; `;
    if(unitTemperature.value)
      string += `unit_temperature=${unitTemperature.value}; `;
    if(unitWeight.value)
      string += `unit_weight=${unitWeight.value}; `;
    if(unitTds.value)
      string += `unit_tds=${unitTds.value}; `;
    if(unitLength.value)
      string += `unit_length=${unitLength.value}; `;
    if(authGuest.value)
      string += `auth:guest=${authGuest.value}; `;
    if(details.value)
      string += `details=${details.value}; `;

      // console.log('cookies string');
      // console.log(string);
    return string;
  }

  const getHeader = (name: string) => {
    const headers = useRequestHeaders([name]) 
    return headers[name];
  }
  
  
  // cookies
  const userAuth = useCookie('auth:token',{maxAge: 60 * 60 * 24 * 31 * 6})
  const guestAuth = useCookie('auth:guest',{maxAge: 60 * 60 * 24 * 31 * 6})
  // const apiKey = useApiSecret();
  const apiKey = useCookie('auth:api',{maxAge: 60 * 60 * 24 * 31 * 6}).value ? useCookie('auth:api',{maxAge: 60 * 60 * 24 * 31 * 6}) : useApiSecret();
  // 

 
  const debugFor = useDebugListFor();

  const configOrigin = ref('');
  const configApiSecret = ref('');
  const config = useRuntimeConfig()
  const { hooks, $uniq } = useNuxtApp()

  const event = useRequestEvent()
  // const headers = useRequestHeaders(['cookie']) as HeadersInit
  // url = (import.meta.server ? config.public.baseAPILocal : config.public.baseAPI) + url.replace(/^\/api/, '');

  // console.log('import.meta.client');
  // console.log(import.meta.client ? 1 : 0);
  // console.log('----');
  
  if(import.meta.server){
    configOrigin.value = config.origin ?? ''
    configApiSecret.value = config.apiSecret ?? ''
  }

  
  url = url.replace(/^\/api/, '');

  // console.log('options');
  // console.log(options);
  // console.log(options.domain);

  if(import.meta.server){
    url = config.public.baseAPILocal + url;
  }else{    
    if(options.domain == 'UPLOAD'){
      url = config.public.baseAPIUpload + url;
    }else{
      url = config.public.baseAPI + url;
    }
  }

  var uniq = '';
  var rniq = '';
  if(import.meta.client){
    let tmpUniq = $uniq.code();
    uniq = tmpUniq.code;
    rniq = tmpUniq.rnd;
  }
  
  const headers = 
  {
    'Authorization': userAuth.value ? `Bearer ${userAuth.value}` : '',
    'X-Access': apiKey.value ?? '',
    'X-Guest': guestAuth.value ?? '',
    'X-Origin': configOrigin.value ?? '',
    'X-Pass': configApiSecret.value ?? '',
    'X-Uniq': uniq ?? '',
    'X-Rniq': rniq ?? '',
    'Cookie': getCookieString(),
    // 'HTTP_CF_IPCOUNTRY': getHeader('HTTP_CF_IPCOUNTRY') ?? '',
  }

  if(import.meta.server){
    headers['CF-IPCountry'] =getHeader('cf-ipcountry');
  }


  const defaults: UseFetchOptions<T> = {

    key: url + Math.random(),
    // mode: 'cors',
    credentials: 'include',

    // headers: userAuth.value
    //   ? { Authorization: `Bearer ${userAuth.value}` }
    //   : {},

    headers: headers,
 
    onResponse (_ctx) {
      // _ctx.response._data = new myBusinessResponse(_ctx.response._data)
      // console.log('custom use fetch onResponse');
      // console.log(_ctx.response.status);
      // if(_ctx.response.status != 200){

      // }


      if(_ctx.response.status >= 400) {
        throw (_ctx.response._data?.message);
      }

      
      // throw new Error('Test error')

      if(import.meta.server){
        const cookies = (_ctx.response.headers.get('set-cookie') || '').split(',')
         

        var cookies_normal = [];
        var cookies_r = '';
        for (const cookie_row of cookies) {
          if(cookie_row.indexOf('expires=') >= 0){
            if(cookies_r) 
              cookies_normal.push(cookies_r);
            cookies_r = cookie_row;
          }else{
            cookies_r += cookie_row;
          }
        }

        for (const cookie_row of cookies_normal) {
          appendResponseHeader(event, 'set-cookie', cookie_row)
        }

      }



      if(!options.method){
        hooks.callHook('custom:fetch:get', _ctx.response._data).catch(() => {})
      }
      
      hooks.callHook('custom:fetch:any', _ctx.response._data).catch(() => {})
      

      if(_ctx.response._data?.details){
        debugFor.value.push(_ctx.response._data); 
      }


    },

    onResponseError (_ctx) {
      // console.log('error api ');
      // console.log(_ctx); 
      throw new Error('')
    }
  }

  // for nice deep defaults, please use unjs/defu
  // const params = 

  return useFetch(url, defu(options, defaults))
}
